import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.backgroundId = 'modal-background';

    this.backgroundHtml = `<div id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full bg-gray-70 bg-opacity-75" style="z-index: 9998;"></div>`;
  }

  disconnect() {
    this.close();
  }

  open(e) {
    e.preventDefault();

    e.target.blur();

    this.lockScroll();

    this.containerTarget.classList.remove('hidden');

    document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
    this.background = document.querySelector(`#${this.backgroundId}`);
  }

  close(e) {
    if (e) { e.preventDefault() }

    this.unlockScroll();

    this.containerTarget.classList.add('hidden');

    if (this.background) { this.background.remove() }
  }

  closeBackground(e) {
    if (e.target === this.containerTarget) {
      this.close(e);
    }
  }

  // Support esc key close by default
  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains('hidden')) {
      this.close(e);
    }
  }

  // Locking and unlocking scroll is a bit finicky, but it's very surprising when the website moves behind a modal.
  // css-tricks thoughts: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  lockScroll() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    this.saveScrollPosition();

    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    document.body.style.paddingRight = null;

    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    this.restoreScrollPosition();

    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
