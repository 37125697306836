import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["target", "template"]

    add(e) {
        e.preventDefault()

        const content = this.templateTarget.innerHTML;
        this.targetTarget.insertAdjacentHTML("beforeend", content)
    }

    remove(e) {
        e.preventDefault()

        e.target.parentElement.remove()
    }
}
