import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["container"]

    connect() {
        const inputs = Array.from(this.containerTarget.getElementsByTagName("INPUT"))
        this.checkbox = inputs.find(input => input.type === "checkbox")
        if (this.checkbox === undefined) {
            console.error("Unable to find checkbox amongst children")
            return
        }

        this.toggleListener = this.toggle.bind(this)
        this.checkbox.addEventListener('input', this.toggleListener)
        this.checkbox.classList.add("h-4", "w-4", "border-gray-30", "text-blue-70", "focus:ring-blue-70")

        this.updateStyles(this.checkbox.checked)
    }

    disconnect() {
        this.checkbox.removeEventListener('input', this.toggleListener)
    }

    toggle(event) {
        this.updateStyles(event.target.checked)
    }

    updateStyles(checked) {
        if (checked) {
            this.containerTarget.classList.remove("bg-white", "border-gray-30")
            this.containerTarget.classList.add("selected-card")
        } else {
            this.containerTarget.classList.remove("selected-card")
            this.containerTarget.classList.add("bg-white", "border-gray-30")
        }
    }
}
