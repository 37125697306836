import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["showable", "container", "select"]

    connect() {
        const selectedShowId = this.selectTarget.dataset["initialShowId"]
        if (selectedShowId) {
            this.updateSelection(selectedShowId)
        }
    }

    change(event) {
        const selectedShowId = event.target.selectedOptions[0].value;
        this.updateSelection(selectedShowId)
    }

    updateSelection(selectedShowId) {
        let container = this.containerTarget
        let setContainer = false;
        this.showableTargets.forEach(showable => {
            if (showable.dataset["showId"] === selectedShowId) {
                container.innerHTML = showable.innerHTML
                setContainer = true
            }
        })
        if (setContainer === false) {
            container.innerHTML = ""
        }
    }
}
