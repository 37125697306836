import {Controller} from "@hotwired/stimulus";

const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'

export default class extends Controller {
    static targets = ["container"]

    connect() {
        this.radioButtonTarget = this.containerTarget.getElementsByTagName("INPUT")[0]
        this.toggleListener = this.toggle.bind(this);
        this.radioButtonTarget.addEventListener('input', this.toggleListener);

        this.radioButtonTarget.classList.add("h-4", "w-4", "border-gray-30", "text-blue-70", "focus:ring-blue-70")
        this.updateStyles(this.radioButtonTarget.checked)

        document.addEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
    }

    disconnect() {
        document.removeEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
        this.element.removeEventListener('input', this.toggleListener);
    }

    toggle(event) {
        event.stopImmediatePropagation()

        const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
            detail: {
                name: event.target.name,
                value: event.target.value
            }
        });
        document.dispatchEvent(selectionEvent);
        return false;
    }

    handleSelection(event) {
        if (event.detail.name === this.radioButtonTarget.name) {
            this.updateStyles(event.detail.value === this.radioButtonTarget.value)
        }
    }

    updateStyles(checked) {
        if (checked) {
            this.containerTarget.classList.remove("bg-white","border-gray-30")
            this.containerTarget.classList.add("selected-card")
        } else {
            this.containerTarget.classList.remove("selected-card")
            this.containerTarget.classList.add("bg-white","border-gray-30")
        }
    }
}
