import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "checkbox"]

  connect() {
    this.toggleListener = this.toggleInput.bind(this)
    this.checkboxTarget.addEventListener('input', this.toggleListener)
    this.updateAttribute(this.checkboxTarget)
  }

  disconnect() {
    this.checkboxTarget.removeEventListener('input', this.toggleListener)
  }

  toggleInput(event) {
    this.updateAttribute(event.target)
  }

  updateAttribute(input) {
    if (input.checked) {
      this.containerTarget.setAttribute('data-checkbox-input-reference-checked', true)
    } else {
      this.containerTarget.removeAttribute('data-checkbox-input-reference-checked')
    }
  }
}
