import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selected: Boolean,
    selectedClassName: String,
    selectedCheckClassName: String
  }

  static targets = ["input", "checkWrapper", "check"]

  connect() {
    let indeterminate = this.inputTarget.getAttribute("indeterminate")

    if (indeterminate) {
      this.inputTarget.indeterminate = true
    }
  }

  toggle() {
    this.selectedValue = !this.selectedValue
    this.inputTarget.checked = this.selectedValue
  }

  selectedValueChanged(newValue, oldValue) {
    this._toggleSelected(newValue)
    this._toggleChecked(newValue)
  }

  deselect() {
    this.selectedValue = false
  }

  _toggleChecked(value) {
    this.inputTarget.setAttribute("aria-checked", value)

    if (value) {
      this.inputTarget.setAttribute("checked", "checked")
      this.checkWrapperTarget.classList.add(this.selectedCheckClassNameValue)
      this.checkTarget.classList.remove("hidden")
    } else {
      this.inputTarget.removeAttribute("checked")
      this.checkWrapperTarget.classList.remove(this.selectedCheckClassNameValue)
      this.checkTarget.classList.add("hidden")
    }
  }

  _toggleSelected(value) {
    if (value) {
      this.element.classList.add(this.selectedClassNameValue)
    } else {
      this.element.classList.remove(this.selectedClassNameValue)
    }
  }
}
