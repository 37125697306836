import {Controller} from '@hotwired/stimulus'
import Swiper  from 'swiper/bundle'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.swiper = new Swiper(`#${this.element.id}`, {
      ...this.defaultOptions(),
      ...this.optionsValue
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  defaultOptions() {
    return {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      }
    }
  }
}
