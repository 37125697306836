// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import Reveal from "stimulus-reveal-controller"
import { Autocomplete } from 'stimulus-autocomplete'
import NestedForm from "stimulus-rails-nested-form"
import Sortable from 'stimulus-sortable'
import AutoSubmit from "@stimulus-components/auto-submit"

application.register("reveal", Reveal)
application.register("autocomplete", Autocomplete)
application.register("nested-form", NestedForm)
application.register("sortable", Sortable)
application.register("auto-submit", AutoSubmit)

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

