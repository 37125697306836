import {Controller} from "@hotwired/stimulus";

const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'

export default class extends Controller {
  static targets = ["container", "radioButton"]

  connect() {
    this.toggleListener = this.toggleInput.bind(this)
    this.radioButtonTarget.addEventListener('input', this.toggleListener)

    document.addEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
    if (this.radioButtonTarget.checked) {
      this.broadcastSelection(this.radioButtonTarget);
    }
  }

  disconnect() {
    document.removeEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
    this.radioButtonTarget.removeEventListener('input', this.toggleListener)
  }

  toggleInput(event) {
    event.stopImmediatePropagation()
    this.broadcastSelection(event.target)
    return false;
  }

  broadcastSelection(target) {
    const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
      detail: {
        name: target.name,
        value: target.value
      }
    });
    document.dispatchEvent(selectionEvent);
  }

  handleSelection(event) {
    if (event.detail.name === this.radioButtonTarget.name) {
      this.updateAttribute(event.detail.value === this.radioButtonTarget.value)
    }
  }

  updateAttribute(checked) {
    if (checked) {
      this.containerTarget.setAttribute('data-radio-button-input-reference-checked', true)
    } else {
      this.containerTarget.removeAttribute('data-radio-button-input-reference-checked')
    }
  }
}
