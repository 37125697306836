import CompactRadioButtonCardController from "./compact_radio_button_card_controller"

export default class extends CompactRadioButtonCardController {
    static targets = ["container", "uncheck", "check"]

    updateStyles(checked) {
        if (checked) {
            this.checkTarget.classList.remove("hidden")
            this.containerTarget.classList.remove("bg-white", "border-gray-30")
            this.containerTarget.classList.add("selected-card")
            this.uncheckTarget.classList.add("hidden")
        } else {
            this.uncheckTarget.classList.remove("hidden")
            this.containerTarget.classList.remove("selected-card")
            this.containerTarget.classList.add("bg-white", "border-gray-30")
            this.checkTarget.classList.add("hidden")
        }
    }
}
