import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = ['trigger', 'source'];
    static values = {
        successDuration: {
            default: 2000
        }
    }

    connect() {
        if (!this.hasTriggerTarget) return;
        this.originalText = this.triggerTarget.innerText;
    }

    copy(e) {
        e.preventDefault();

        // write text content from target element to the clipboard using Clipboard API instead of execCommand
        navigator.clipboard.writeText(this.sourceTarget.textContent);
        this.copied();
    }

    copied() {
        if (!this.hasTriggerTarget) return;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.triggerTarget.innerText = this.data.get('successContent');

        this.timeout = setTimeout(() => {
            this.triggerTarget.innerText = this.originalText;
        }, this.successDurationValue);
    }
}