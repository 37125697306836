import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

const POPPER_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 5],
    },
  },
  {
    name: 'flip',
    options: {
      allowedAutoPlacements: ['top-start', 'bottom-start', 'top-end', 'bottom-end']
    },
  }
]


export default class extends Controller {
  static targets = ["activator", "popover"]
  static classes = ["closed"]
  static values = {
    placement: String,
    active: Boolean
  }

  connect() {
    this.popper = createPopper(this.activatorTarget, this.popoverTarget, {
      placement: this.placementValue,
      modifiers: POPPER_MODIFIERS
    })
    if (this.activeValue) {
      this.show()
    }
  }

  toggle() {
    this.popoverTarget.classList.toggle(this.closedClass)
    if (!this.popoverTarget.classList.contains(this.closedClass)) {
      this.show()
    }
  }

  async show() {
    this.popoverTarget.classList.remove(this.closedClass)
    await this.popper.update()
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.popoverTarget.classList.contains(this.closedClass)) {
      this.forceHide()
    }
  }

  forceHide() {
    this.popoverTarget.classList.add(this.closedClass)
  }
}

