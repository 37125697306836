import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['input']

  static values = {
    minDate: String,
    maxDate: String,
    enabledDates: {
      type: Array,
      default: []
    },
    autoSubmit: {
      type: Boolean,
      default: false
    },
    formId: String
  }

  connect() {
    let parameters = {
      inline: true,
      defaultDate: this.inputTarget.value,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue
    }

    if (parameters.defaultDate && parameters.minDate) {
      parameters.minDate = Math.min.apply(null, [this.minDateValue, parameters.defaultDate])
    }

    if (this.enabledDatesValue !== undefined) {
      parameters.enable = this.enabledDatesValue
    }

    this.calendar = flatpickr(this.inputTarget, {
      ...parameters,
      onChange: function (selectedDates, dateStr, instance) {
        if (this.autoSubmitValue) {
          instance.element.dataset.date = dateStr
          instance.element.value = dateStr
          this.form.requestSubmit()
        }
      },
      disable: [
        function (date) {
          return date < new Date()
        }]
    })
  }

  get form() {
    return document.getElementById(this.formIdValue)
  }
}

