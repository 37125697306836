import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["element", "tooltip"]
  static values = {
    placement: { type: String, default: "bottom" },
    offset: { type: Array, default: [0, 8] },
    elementShowClasses: { type: String, default: "" },
    elementHideClasses: { type: String, default: "" },
    hiddenClass: { type: String, default: "hidden"  },
    boundaryParent: { type: String, default: "" }
  }

  connect() {
    const boundaryElement = this.boundaryParentValue ?
      document.querySelector(this.boundaryParentValue) : "clippingParents"

    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: "preventOverflow",
          options: { boundary: boundaryElement }
        },
        {
          name: 'flip',
          options: {
            boundary: boundaryElement,
            flipVariations: true,
            fallbackPlacements: ['top', 'right', 'left', 'bottom'],
          }
        },
        {
          name: "offset",
          options: { offset: this.offsetValue }
        }
      ]
    })

    // Set initial color state on instantiation
    this.elementTarget.classList.add(this.elementHideClassesValue)
  }

  toggle(e) {
    // If the tooltip is inside an anchor tag, prevent navigation away
    e.preventDefault();
    if (e.type === 'click' && window.innerWidth > 600) {
      return
    }

    this.tooltipHidden() ? this.show(e) : this.hide(e);
  }

  show(e) {
    if (e.type === 'mouseenter' && window.innerWidth <= 600) return

    this.tooltipTarget.classList.remove(this.hiddenClassValue)
    this.elementTarget.classList.remove(this.elementHideClassesValue)
    this.elementTarget.classList.add(this.elementShowClassesValue)
    this.popperInstance.update();
  }

  windowHide(event) {
    if (!this.element.contains(event.target) && !this.tooltipHidden()) {
      this.hide(event);
    }
  }

  tooltipHidden() {
    return this.tooltipTarget.classList.contains(this.hiddenClassValue)
  }

  hide(e) {
    if (this.tooltipHidden()) return
    if (e.type === 'mouseleave' && window.innerWidth <= 600) return

    this.tooltipTarget.classList.add(this.hiddenClassValue)
    this.elementTarget.classList.remove(this.elementShowClassesValue)
    this.elementTarget.classList.add(this.elementHideClassesValue)
  }

  disconnect() {
    if (this.popperInstance) this.popperInstance.destroy();
  }
}
