import { Controller } from "@hotwired/stimulus"
import Dinero from "dinero.js"

export default class extends Controller {
  static targets = ["displayTotal", "totalPriceCents"]
  static values = {total: {type: Number, default: 0} }

  initialize() {
    this.totalPriceCentsTarget.value = this.totalValue
  }

  totalValueChanged() {
    this.totalPriceCentsTarget.value = this.totalValue
    this.displayTotalTarget.innerHTML = Dinero({ amount: this.totalValue }).toFormat()
  }

  /**
   * We get the price as a param, in the form of 'cents' so it's an integer
   * which we can safely add/subtract without needing to convert to Money
   */
  calculate(event) {
    if (event.target.checked) {
      this.addPrice(event.params)
    } else {
      this.subtractPrice(event.params)
    }
  }

  addPrice({ price }) {
    this.totalValue = this.totalValue + price
  }

  subtractPrice({ price }) {
    this.totalValue = this.totalValue - price
  }
}
