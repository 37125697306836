import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["container", "trigger"]

    show(e) {
        e.preventDefault();
        e.target.blur();

        this.containerTarget.classList.remove("hidden");
        this.triggerTarget.classList.add("hidden");
    }
}
